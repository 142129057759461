<template>
  <div class="p-5">
    <h1>{{ $t("SCTA_COMMERCIAL_DISCLOSURE") }}</h1>
    <p style="white-space: pre-wrap">{{ $t("SCTA_DESCRIPTION") }}</p>
    <h2>{{ $t("SCTA_LEGAL_NAME") }}</h2>
    <p>
      {{ $t("SCTA_LEGAL_NAME_A") }}
    </p>
    <h2>{{ $t("SCTA_ADDRESS") }}</h2>
    <p>{{ $t("SCTA_ADDRESS_A") }}</p>
    <h2>{{ $t("SCTA_HEAD_OF_OPERATIONS") }}</h2>
    <p>{{ $t("SCTA_HEAD_OF_OPERATIONS_A") }}</p>
    <h2>{{ $t("SCTA_ADDIONAL_FEES") }}</h2>
    <p>{{ $t("SCTA_ADDIONAL_FEES_A") }}</p>
    <h2>{{ $t("SCTA_EXCHANGES_AND_RETURNS_POLICY") }}</h2>
    <p>{{ $t("SCTA_EXCHANGES_AND_RETURNS_POLICY_A") }}</p>
    <h2>{{ $t("SCTA_DELIVERY_TIMES") }}</h2>
    <p>{{ $t("SCTA_DELIVERY_TIMES_A") }}</p>
    <h2>{{ $t("SCTA_ACCEPTED_PAYMENT_METHODS") }}</h2>
    <p>{{ $t("SCTA_ACCEPTED_PAYMENT_METHODS_A") }}</p>
    <h2>{{ $t("SCTA_PAYMENT_PERIOD") }}</h2>
    <p>{{ $t("SCTA_PAYMENT_PERIOD_A") }}</p>
    <h2>{{ $t("SCTA_PRICE") }}</h2>
    <p>{{ $t("SCTA_PRICE_A") }}</p>
  </div>
</template>

<script>
export default {
  name: "CommercialDisclosure",
};
</script>

<style scoped>
h1 {
  margin-bottom: 40px;
  font-weight: bold;
}
h2 {
  margin-top: 40px;
  margin-bottom: 40px;
  font-weight: bold;
}
h3 {
  margin-top: 30px;
  margin-bottom: 30px;
  font-weight: bold;
}
p {
  margin-bottom: 30px;
  font-size: 18px;
}
</style>
